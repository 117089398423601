<template>
  <div id="about-body">
    <div class="page-view">
      <Article :article="para1" />
      <Article :article="para2" />
      <Article :article="para3" />

      <img src="/about-us-cropped.png" alt="About Us" class="mb-5" />
      <h4 class="sub-header my-5">Our Philosophy</h4>

      <p><span class="bold">A</span> – Approachable</p>
      <p><span class="bold">T</span> – Talent</p>
      <p><span class="bold">E</span> - Emerging</p>
      <p><span class="bold">S</span> – Solutions</p>
      <h4 class="sub-header my-5">Mission Statement</h4>
      <Article :article="missionStatement" />
      <h4 class="sub-header">Vision</h4>
      <Article :article="vision" />
      <h4 class="sub-header">Values</h4>
      <ul class="mt-2">
        <li>Integrity</li>
        <li>Passion</li>
        <li>Diversity</li>
        <li>Excellence</li>
        <li>Innovation</li>
      </ul>
    </div>
    <Footer />
  </div>
</template>

<script>
import Article from '@/components/UI/Article.vue';
import Footer from '@/components/UI/Footer.vue';
import {
  para1,
  para2,
  para3,
  vision,
  missionStatement,
} from '@/assets/scripts/about.js';
export default {
  components: { Article, Footer },
  data: () => {
    return {
      color: '',
      para1,
      para2,
      para3,
      missionStatement,
      vision,
    };
  },
  methods: {},
};
</script>

<style scoped>
img {
  max-height: 350px;
  width: 100%;
  object-fit: cover;
}
.page-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.sub-header,
.bold {
  font-weight: bold;
}
</style>
