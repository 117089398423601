const para1 = {
  alignment: 'text-justify',
  text: `ATES Consulting is one of the leading
  recruitment companies in the country and is prominent global player
  providing its services to large multinationals and big business
  conglomerates. We recruit talent from across the globe for emerging
  markets and offer clients and candidates alike a more knowledge,
  specialist, and mature approach to various industry sectors of
  excellence.`,
};

const para2 = {
  alignment: 'text-justify',
  text: `We leverage a vast recruiting network to improve speed, quality and
  hiring efficiencies, comprising of advertisement of recruitment, Job
  Board, Social Media, Networking and Headhunting, or an end-to-end
  solution supported by the latest HR technology.`,
};

const para3 = {
  alignment: 'text-justify',
  text: `Certified recruiters compete to find the best local candidates for each
  job. Clients receive the highest quality service and perfectly matched
  candidates.`,
};

const missionStatement = {
  alignment: 'text-justify',
  text: `To be a valued partner to our clients and help our clients grow their business by providing innovative and effective solutions to meet their need. To provide you with realistic and executable recommendations to help you make the best decisions for the successful growth of your business`,
};

const vision = {
  alignment: 'text-justify',
  text: `To create and deliver innovative solutions that will revolutionize the way businesses manage
  their recruitment, operations, and compliance processes.`,
};

export { para1, para2, para3, missionStatement, vision };
